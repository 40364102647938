@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

.main-title {
    font-size: 5em;
}
